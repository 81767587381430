<template>
	<div>
		<TableList :form_data_seo.sync="form_data_seo" :columns="columns" :get_table_list="this.get_table_list"
			:slot_table_list="['outcome_id','status','total_num']" @changeStatus='changeStatus'
			:submit_preprocessing="submit_preprocessing" ref="list">
			<template slot="form_after">
				<div class='page_content_top page_content'>
					<div v-for='item in tracing_count'>
						<a-statistic :title="item.label" :value="item.value" />
					</div>
				</div>
			</template>
			<template slot="total_num" slot-scope="data">
				<span>
					<a  @click="go_list_detail(data.record)">{{data.record.total_num}}</a>
				</span>
			</template>
			
			<template slot="outcome_id" slot-scope="data">
				<span>
					<a  @click="go_detail(data.record)">{{data.record.outcome_id}}</a>
				</span>
			</template>
		</TableList>
		<!-- 溯源二维码 -->
		<a-modal width='90%' title="溯源码" :maskClosable='true' :visible="visible" @cancel='visible=false'
			@ok='visible=false'>
			<List></List>
		</a-modal>
	</div>
</template>

<script>
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import List from "./List";
	import TableList from "@/components/TableList";
	import {
		getOutcomeList,
		getOutcomeCount,
		getFactorySelect,
		getWarehouseSelect,getSellerInfoSelect
	} from "@/api/traceability";

	let columns = [{
			title: "出库单ID",
			dataIndex: "outcome_id",
			scopedSlots: {
				customRender: "outcome_id"
			}
		},
		{
			title: "出库仓库",
			dataIndex: "warehouse_name",
		},

{
			title: "品项数量",
			dataIndex: "goods_num",
		},
		{
			title: "托盘垛码数量",
			dataIndex: "guid_num",
		},
		{
			title: "出货数量(个)",
			dataIndex: "total_num",
			scopedSlots: {
				customRender: "total_num"
			}
		},

		{
			title: "经销商",
			dataIndex: "seller_name",

		},
		{
			title: "出库时间",
			dataIndex: "outcome_time",
		},


		// {
		// 	title: "操作",
		// 	scopedSlots: {
		// 		customRender: "operation"
		// 	}
		// },
	];

	export default {
		name: "gpoodIndex",
		components: {
			EditPop,
			Form,
			TableList,
			List
		},
		data() {
			return {
				tracing_count: [],
				get_table_list: getOutcomeList,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				visible: false,
				form_data_seo: {
					list: [
						// {
						// 	type: "text",
						// 	name: "pallet_guid",
						// 	title: "虚拟码",
						// 	placeholder: '虚拟码',
						// 	options: {

						// 	}
						// },
						{
							type: "text",
							name: "outcome_id",
							title: "出库单ID",
							placeholder: '出库单ID',
							options: {}
						},
						{
							type: 'select',
							name: 'warehouse_id',
							title: '仓库',
							list: [],
							options: {},
						}, {
							type: 'select',
							name: 'sales_seller_id',
							title: '经销商',
							list: [],
							options: {},
						},
						// {
						// 	type: "text",
						// 	name: "goods_name",
						// 	title: "产品名称",
						// 	placeholder: '产品名称',
						// 	options: {}
						// },
						{
							type: "range_date",
							name: "range_date",
							title: "时间区间",
							options: {},
							start: {
								name: 'start_time'
							},
							end: {
								name: 'end_time'
							},
						},

					],
					...this.$config.form_data_seo
				},


				submit_preprocessing: {
					array_to_string: ['cate_id', 'department_ids', 'cost_department_ids']
				}
			};
		},
		async created() {
			this.get_Tracing_Count();
			this.get_option()
		},
		methods: {
			go_list_detail(e){
				this.$router.push({
					path: "/traceability/code",
					query: {
						outcome_id: e.outcome_id,
					}
				});
			},
			get_option() {
				// 仓库数据
				getWarehouseSelect().then(res => {
					this.$method.set_form_list(this.form_data_seo.list, 'warehouse_id', 'list', res.data.list);
				})
				getSellerInfoSelect().then(res=>{
					this.$method.set_form_list(this.form_data_seo.list, 'sales_seller_id', 'list', res.data.list);
				})
			},
			go_detail(e) {
				this.$keep_route.add(this.$options.name);
				this.$router.push({
					path: "/send/details",
					query: {
						id: e.delivery_id,
					}
				});
			},
			changeStatus(data){
				this.get_Tracing_Count(data)
			},
			get_Tracing_Count(data) {
				getOutcomeCount({data:{
					...data
				}}).then(res => {
					this.tracing_count = res.data.list
				})
			},


		}
	};
</script>

<style lang="less">
	@import url("../../../assets/less/app.less");

	.float_left {
		margin-right: 8px;
	}

	.page_content_top {
		display: flex;

		div {
			flex: 1;
			text-align: center;
		}

		div:nth-child(3) {
			cursor: pointer;
		}
	}
</style>