<template>
    <PopTableList
        :zIndex="2000"
        :columns="pop_columns"
        :visible.sync="user_visible"
        :get_table_list="get_table_list"
        :submit_preprocessing="submit_preprocessing"
        @submit="submit"
        rowKey="id"
		:where='where'
		ref="list"
		:is_created_get_list="JSON.stringify(seo_data) === '{}'"
    >

    </PopTableList>
</template>

<script>
import PopTableList from './PopTableList'
import { getScanCodeLog } from "@/api/traceability";
import props_pop from './props/pop'
import table_pop from './props/table'
const columns = [
    {
        title: "扫描时间",
        dataIndex: "create_time"
    },
    {
        title: "扫描来源",
        dataIndex: "type_name"
    },
    {
        title: "扫描地址",
        dataIndex: "address"
    }
]

export default {
    name: 'SelectUser',
    components: {
        PopTableList,
    },
    props: {
        ...props_pop,
        ...table_pop,
		seo_data:{
			type:Object,
			data:()=>{
				return {
					// customer_type:'', // eg: customer_type:'dealer'，目前该字段只有dealer值，用于只筛选出经销商
				}
			}
		},
		where:{
			type:Object,
			data:()=>{
				return {
					// customer_type:'', // eg: customer_type:'dealer'，目前该字段只有dealer值，用于只筛选出经销商
				}
			}
		}
    },

    data() {
        return {
			
            user_visible: false,
            get_table_list: getScanCodeLog,
            pop_columns: columns,
            submit_preprocessing: {
            },
            form_data_pop: {
                title: '扫描记录'
            },
            form_data_seo_pop: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        placeholder: "客户名称/地址/ID",
                        options: {}
                    }
                ]
            },
            select_user: {}
        }
    },
    computed: {
        
    },
    watch: {
        visible(new_value) {
		
            this.user_visible = new_value
			// 只有当modal显示时真正的dom才会开始加载，在$nextTick之后将父级的搜索内容填充进table_list中
			this.$refs.list.$nextTick(()=>{
					console.log('where',this.where)
				let list = this.$refs.list.$refs.table_list;
				list.get_list();
			})
        },
        user_visible(new_value) {
            this.$emit('update:visible', new_value)
        },
    },
    created() {
        
    },
    methods: {
        submit() {
           
        }
    }
}
</script>